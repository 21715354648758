.nav-text{
    color: white;
    cursor: pointer;
    padding: 5px;
}
.nav-text:hover{
    color: gold;
    text-decoration: underline;
}
.nav-text:active{
    color: orange;
    text-decoration: underline;
}
.nav-text:visited{
    color: orange;
    text-decoration: underline;
}
.some{
    color:rgb(128, 128, 128)
}
.strategy{ padding: 20; color: maroon; cursor: pointer;}
.strategy:hover{
    color: gold;
    /* background-color: rgba(128, 0, 0, 0.4); */
}
.limited-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to display */
    -webkit-box-orient: vertical;
    white-space: normal;
    word-break: break-word;
}
